import Head from 'next/head';

type Props = {
  link?: string;
  title?: string;
  description?: string;
  image?: string;
};

const site_title = 'BRINGMOS';
const site_description = 'What do you feel like eating today?';

const host =
  process.env.NEXT_PUBLIC_HOST ||
  (process.env.VERCEL_URL ? `https://${process.env.VERCEL_URL}` : 'https://www.bringmos.com');

export default function OpenGraphMeta({ link, title, description, image }: Props) {
  return (
    <Head>
      <meta property="og:site_name" content={site_title} />
      <meta property="og:url" content={link ? host + link : host} />
      <meta property="og:title" content={title ? [title, site_title].join(' | ') : site_title} />
      <meta property="og:description" key="og:description" content={description ? description : site_description} />
      <meta property="og:image" content={`${host}/images/preview.png`} key="og:image" />
      <meta property="og:type" key="og:type" content="website" />
    </Head>
  );
}
