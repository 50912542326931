import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

import Image from 'next/image';
import { useEffect, useState } from 'react';
import Language from './Language';
import Theme from './Theme';

const Footer = () => {
  const { t } = useTranslation('footer');

  const [host, setHost] = useState('');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHost(window.location.host);
    }
  }, []);

  const isBringmos = host.includes('bringmos');

  return (
    <footer className="bg-background text-on-background border-t border-outline-variant dark:text-white text-sm pt-12 w-full relative overflow-hidden">
      <div className="max-w-[90rem] mx-auto flex-col px-8 pb-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-12 z-10">
          <div>
            <div className="flex flex-col items-center justify-center md:items-start z-10">
              <div className="mb-2">
                <a
                  className="text-tertiary text-4xl hover:font-semibold"
                  href="https://facebook.com/bringmos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lab la-facebook"></i>
                </a>
                <a
                  className="text-tertiary text-4xl hover:font-semibold"
                  href="https://www.instagram.com/bringmos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lab la-instagram"></i>
                </a>
              </div>
              <span className="md:mr-6 text-secondary">© 2019 - {new Date().getFullYear()} All Rights Reserved.</span>
            </div>
            <div className="relative z-20 flex flex-row justify-center md:justify-start">
              <Theme></Theme>
            </div>
            <div className="relative z-20 flex flex-row justify-center md:justify-start">
              <Language></Language>
            </div>
          </div>
          <div className="font-normal ">
            <h5 className="text-xl mb-6 font-semibold">{t('product.title')}</h5>
            <Link href="/restaurants/all" className="my-2 block">
              {t('product.restaurants')}
            </Link>
            <Link href="https://bringmos.io" target="_blank" className="my-2 block">
              {t('product.managementplatform')}
            </Link>
            <Link href="https://bringmos.io/features#terminal" target="_blank" className="my-2 block">
              {t('product.terminal')}
            </Link>
            <Link href="https://docs.bringmos.com" target="_blank" className="my-2 block">
              {t('product.docs')}
            </Link>

            <div className="flex items-center flex-wrap mt-4">
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://play.google.com/store/apps/details?id=com.bringmos.bringmosclient"
              >
                <img height="48px" className="h-full object-contain" src="/images/playstore.png" loading="lazy" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://apps.apple.com/us/app/bringmos/id1497420702?l=de&ls=1"
              >
                <img height="48px" className="h-full object-contain" src="/images/appstore.svg" loading="lazy" />
              </a>
            </div>
          </div>

          <div className="">
            <h5 className="text-xl mb-6 font-medium">{t('solutions.title')}</h5>
            <Link href="https://bringmos.io/features#menuonly" className="my-2 block">
              {t('solutions.menuonly')}
            </Link>
            <Link href="https://bringmos.io/features#manage" className="my-2 block">
              {t('solutions.manage')}
            </Link>
            <Link href="https://bringmos.io/features#more" className="my-2 block">
              {t('solutions.orderonline')}
            </Link>
            <Link href="https://bringmos.io/features" className="my-2 block">
              {t('solutions.paymentmethods')}
            </Link>
          </div>

          <div className="">
            <h5 className="text-xl mb-6 font-medium">{t('links.title')}</h5>

            <Link href="https://bringmos.io/register" className="my-2 block">
              {t('links.register')}
            </Link>
            <Link href="/legal" className="my-2 block">
              {t('links.legal')}
            </Link>
            <Link href="/contact" className="my-2 block">
              {t('links.contact')}
            </Link>
            <Link href="/impressum" className="my-2 block">
              {t('links.impressum')}
            </Link>
          </div>
        </div>
      </div>
      {isBringmos ? (
        <div className="flex flex-row items-center justify-center pb-12">
          <div className="block dark:hidden">
            <Image
              height={40}
              width={100}
              src="/images/made_in_southtyrol_light.svg"
              alt="made in southtyrol"
              // className="w-auto"
            />
          </div>

          <div className="hidden dark:block">
            <Image
              height={40}
              width={100}
              src="/images/made_in_southtyrol_dark.svg"
              alt="made in southtyrol"
              // className="w-auto"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-center pb-12">
          <Link href="https://bringmos.io" target="_blank">
            <div className="block dark:hidden">
              <Image
                height={60}
                width={150}
                src="/powered/light.svg"
                alt="powered by Bringmos"
                // className="w-auto"
              />
            </div>

            <div className="hidden dark:block">
              <Image
                height={60}
                width={150}
                src="/powered/dark.svg"
                alt="powered by Bringmos"
                // className="w-auto"
              />
            </div>
          </Link>
        </div>
      )}
    </footer>
  );
};

export default Footer;
